import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`“Holleyman”`}</strong></p>
    <p>{`30 Rounds for time:`}</p>
    <p>{`5-Wall Balls (20/14`}{`#`}{`)`}</p>
    <p>{`3-HSPU’s`}</p>
    <p>{`1-Power Clean (225/155)`}</p>
    <p><em parentName="p">{`*`}{`compare to 8/3/18`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      